import * as React from "react"
import { graphql, Link } from "gatsby"
import FrontmatterPageContext from "~context/FakePageContext"
// import { LinkDataURL } from "~components/data_url"
import { Box, Text } from "@fastly/consistently"
import { main } from "~vanilla/layout-default.css"
import { mainArea } from "~vanilla/layout-default-full.css"

import LayoutCore from "~templates/layout-core"

import NavProductData from "~data/nav_products.yml"

import { FavHead } from "~components/Head/FavHead"

import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { CodeBlock } from "~components/CodeBlock"
import Alert from "~components/alert"
import T from "~components/translator"
import mailto from "~components/mailto"
import DRY from "~components/dry"
import Raw from "~components/raw"
import Frontmatter from "~components/frontmatter"
import { LimitedAvailBadge } from "~components/avail-badges"
import FloatedImage from "~components/floated-image"
import { PageHeaderTable, DinkusBar } from "~components/AIOHelper/AIOHelper"

import * as Includes from "~components/includes"

// these came from the /index.md page
let frontmatter = {
  // layout: "default_full",
  section: "guides",
  section_safe: "guides",
  title: "Fastly Products Archive | Fastly Documentation",
  lang: "en",
}

// for MDXProvider later
let mdx_components = {
  T,
  Alert,
  mailto,
  ...Includes,
  DRY,
  Raw,
  Box,
  CodeBlock,
  pre: CodeBlock,
  FloatedImage,
  Frontmatter,
  LimitedAvailBadge,
}

const ProductsAioPage = ({ data, location }) => {
  let PPrimaryDatas = {}

  NavProductData.forEach((element) => {
    const { crumb: pkey } = element

    let thisPrimary = data.gprimaries_p.nodes.filter((nod) => nod.frontmatter.cat_id === pkey)[0]
    let p_frontmatter = thisPrimary.frontmatter

    p_frontmatter["section_url"] = "/products/"

    // let thisPrimaryGuides = data.guides_in_primarys.group.find((ggip) => ggip.fieldValue === pkey)

    let myOrphans = data.gprimary_orphans_p.group.find((g) => g.fieldValue === pkey)

    PPrimaryDatas[pkey] = {
      pkey: pkey,
      pfrontmatter: p_frontmatter,
      // thisPrimaryGuidesNodes: thisPrimaryGuides?.nodes,
      myOrphans: myOrphans?.nodes,
    }
  })

  return (
    <>
      <FrontmatterPageContext.Provider value={frontmatter}>
        <LayoutCore>
          <div className={`${mainArea} grid-main layout-guide_article`}>
            <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
              <div className="content-content">
                {NavProductData.map((ngd) => {
                  let pdata = PPrimaryDatas[ngd.crumb]
                  if (ngd?.unlisted) {
                    return <></>
                  }
                  let pURL = `${pdata.pfrontmatter.section_url}${pdata.pkey}`

                  return (
                    <section>
                      <Box bg={`var(--color-blue-20)`} border="5px outset #999999" padding="1em" mb="0.5em">
                        <Text variant="display2xl">
                          Category: <a href={pURL}>{pdata.pfrontmatter.section_name}</a>
                        </Text>
                        <p style={{ marginBottom: 0 }}>{pdata.pfrontmatter.description}</p>
                      </Box>

                      {pdata.myOrphans?.map((og) => {
                        return (
                          <>
                            <PageHeaderTable guide={og} section_url_prefix={pdata.pfrontmatter.section_url} />

                            <hr />
                            <FrontmatterPageContext.Provider value={og.frontmatter}>
                              <MDXProvider components={mdx_components}>
                                <MDXRenderer>{og.body}</MDXRenderer>
                              </MDXProvider>
                            </FrontmatterPageContext.Provider>

                            <DinkusBar />
                          </>
                        )
                      })}
                    </section>
                  )
                })}
              </div>
            </main>
          </div>
        </LayoutCore>
      </FrontmatterPageContext.Provider>
    </>
  )
}

export default ProductsAioPage

export const Head = (props) => {
  return (
    <>
      <title>{frontmatter.title}</title>
      <FavHead />
    </>
  )
}

export const sitemapQuery = graphql`
  query {
    gprimaries_p: allMdx(filter: { fileAbsolutePath: { regex: "/_products_primary/" } }) {
      nodes {
        fileAbsolutePath
        frontmatter {
          cat_id
          section_name
          svg
          description
        }
      }
    }
    gprimary_orphans_p: allMdx(
      filter: {
        frontmatter: {
          # orphan: { eq: true },
          unlisted: { ne: true }
          }
        fileAbsolutePath: { regex: "/_products//" }
      }
      sort: { fields: fileAbsolutePath }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        nodes {
          fileAbsolutePath
          slug
          frontmatter {
            primary_nav
            # secondary_nav
            header
            unlisted
            # orphan
            last_updated(formatString: "YYYY-MM-DD")
            excerpt
          }
          body
        }
      }
    }
  }
`
